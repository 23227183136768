import React, { Component } from 'react'

import { MDBRow, MDBCol, MDBTooltip, MDBIcon } from 'mdbreact';

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

export default class SmartToolbar extends Component {
    render() {
        return (
            <ProductConsumer>
                {context => {


                    return (

                        <MDBCol className="noSpace h-100 my-auto py-2" size="12" >
                            <MDBRow className="noSpace  my-auto mx-3 ">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="tooltipToHide2"
                                >

                                    <input type="text" className="toolbarSearchInput toolbarSearchInputText my-auto" placeholder="Ingrese palabras de búsqueda" onChange={e => context.handleSmartInput(e)} />

                                    <span>"Ingrese una palabra o frase para la búsqueda"</span>
                                </MDBTooltip>
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="tooltipToHide2"
                                >

                                    <button className="btn toolbarSearchInput toolbarSearchInputBtn py-0 mx-2 px-1" onClick={e => context.querySmartSearch(e)}>
                                        <MDBIcon icon="search" size="lg"/>
                                    </button>

                                    <span>"Presiona el ícono para realizar la búsqueda"</span>
                                </MDBTooltip>

                            </MDBRow>
                        </MDBCol>

                    )
                }}
            </ProductConsumer>
        )
    }
}
