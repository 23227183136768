import React, { Component } from 'react'
//Import icon reusable component
import MyPagination from "./MyPagination"
import DateToolbar from "./DateToolbar"
import RoToolbar from "./RoToolbar"
//Import material design components
import { MDBCol, MDBRow } from "mdbreact";

export default class BusqSimpleToolbar extends Component {
    render() {
        return (
         
            <MDBRow className="noSpace toolbarContainer mx-auto mt-3 TotalBg py-2 ">
                <MDBCol className="noSpace" size="12" md="5" lg="4">
                    {this.props.isComplete&&<DateToolbar />}
                </MDBCol>
                <MDBCol className="noSpace" size="12" md="7" lg="4">
                    <MyPagination total={this.props.total} limit={this.props.limit} pageCount={this.props.pageCount} />
                </MDBCol>
                <MDBCol className="noSpace h-100 my-auto py-2" size="12" lg="4">
                    {this.props.isComplete&&<RoToolbar searchType="Simple"/>}
                </MDBCol>
            </MDBRow>
      
        )
    }
}
