import React, { Component } from 'react'
import { MDBTypography, MDBContainer, MDBRow, MDBCol } from
    "mdbreact";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"


var textoCompleto=<p>Biblioteca Virtual LHL, denominada así en honor al Fundador de LEXIS; el Dr. Luis Hidalgo López, Gerente y Presidente de la Empresa. El doctor Hidalgo fue un reconocido jurista, investigador social, consultor internacional, hombre de ciencia, empresario, visionario emprendedor y sobre todo profundamente humanista. Comenzó en la década de los años 60 a recopilar información del Registro Oficial en base a fichas mnemotécnicas; información recogida con tal acuciosidad que sirvió de base para que en los años 80s la incipiente ciencia de las computadoras personales recogiera en programas diseñados por profesionales ecuatorianos.<br></br> <br></br>
La evolución de las herramientas de búsqueda en textos impulsada por el Dr. Luis Hidalgo nos ha llevado al día de hoy a la utilización de tecnología de punta en la prestación de consulta de información documental y jurídica en la web de todas nuestras líneas de servicios que se ajustan a cada una de las necesidades de la sociedad. Lexis rinde un homenaje al creador de la primera base de datos jurídica más completa de Latinoamérica con la puesta en marcha de esta Biblioteca Virtual, cuyo concepto evolutivo con diseño minimalista de textos en formato PDF e imágenes, además de crear un repositorio académico, permitirá realizar intercambios de información vía documentos de autoría personal de nuestros clientes, junto con la posibilidad de investigar en textos, será también una vía para ingresar a las bibliotecas multitemáticas de mayor prestigio a nivel mundial.</p>
var TextHint = "Biblioteca Virtual LHL, denominada así en honor al Fundador de LEXIS; el Dr. Luis Hidalgo López, Gerente y Presidente de la Empresa."
var btnText="Leer más"
export default class About extends Component {

    state = {
        collapseID: "",
        width: window.innerWidth,
        text: TextHint,
        showCompleteText:false,
        btnText:btnText
    }
    toggleCollapse = (collapseID) => {

        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    updateText = (state) => {
        if (state===false){
            this.setState({ 
                text: textoCompleto,
                btnText:"Ocultar",
                showCompleteText:true })
        }
        else{
            this.setState({ 
                text: TextHint,
                btnText:"Leer más",
                showCompleteText:false })
        }
        
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth });

    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    render() {

        return (
            <ProductConsumer>
                {value => //value contains the data defined in the product provider in Context.js
                {

                    return (
                        <MDBContainer className="pt-4  mt-4 mt-xl-5 TotalBg  py-2 py-lg-3 py-xl-4  " fluid>
                            <MDBRow  className="noSpace aboutTitle">
                                <MDBCol className="pl-0 offset-xl-2 ">
                                    <MDBTypography tag='h1' variant="h1-responsive" className="aboutTitle px-2 px-lg-3 px-xl-4 my-3 text-center text-xl-left">Sobre la Biblioteca LHL {value.isRoEnabled&&<span style={{color:"var(--mainInfolex"}}>PRO</span>}</MDBTypography>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="noSpace aboutTitle">

                                <MDBCol size="12" xl="2" className="noSpace py-2 py-lg-3 py-xl-4 my-auto  text-center">
                                    <img src="/fotoLHL.jpg" alt="Foto LHL" className="img-fluid w-100 aboutFoto text-center text-xl-left" />
                                </MDBCol>


                                <MDBCol className="noSpace px-0 my-auto py-2">
                                    {
                                        this.state.width > 1400 ?
                                            <MDBTypography tag='h4' variant="h4-responsive" className="aboutText px-2 px-lg-3 px-xl-4">{textoCompleto}</MDBTypography>
                                            : <MDBRow className="noSpace">
                                                <MDBTypography tag='h4' variant="h4-responsive" className="aboutText px-2 px-lg-3 px-xl-4">{this.state.text} <button //link to pdf asset
                    className="aboutBtn" onClick={()=>this.updateText(this.state.showCompleteText)}
                   >
                   {this.state.btnText}
                </button></MDBTypography>
                                                
                                            </MDBRow>}
                                </MDBCol>

                            </MDBRow>



                        </MDBContainer>)
                }
                }

            </ProductConsumer>
        )
    }
}
