//Import React Library
import React, { Component } from 'react'
//Import material design components
import { MDBContainer, MDBView, MDBMask, MDBTypography } from
    "mdbreact";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"
import headerImage from "../../assets/header/header.png"
export default class Header extends Component {
    state = {

        width: window.innerWidth,

    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });

    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <ProductConsumer>
            {value => //value contains the data defined in the product provider in Context.js
            {
                // eslint-disable-next-line no-restricted-globals
                //onload=()=>this.setProduct(value)
                return (
            <MDBContainer fluid className="p-0">
                <MDBView>

                    {this.state.width < 1200 ?
                        <img //image for small screens
                            className="img-fluid w-100"
                            src={headerImage}
                            alt="eclex banner">
                        </img> :
                        <img //image for large screens
                            className="img-fluid w-100"
                            src={headerImage}
                            alt="eclex banner">
                        </img>}


                    <MDBMask className="d-flex justify-content-start align-items-bottom text-white" overlay="black-light" >
                        <MDBContainer className="h-100 d-table px-0 mx-0" fluid>

                            <td className="align-bottom">{/**Title */}
                                <a href="/" >
                                    <MDBTypography className="HomeMaskTitle headerLink px-5 font-weight-bolder">EcLEX</MDBTypography>

                                </a>
                            </td>
                        </MDBContainer>
                    </MDBMask>
                </MDBView>


            </MDBContainer>
             )
            }}
        </ProductConsumer>
        )
    }
}
