//Import React library
import React, { Component } from 'react'
//Import material design components
import { MDBRow } from "mdbreact";

export default class CopyRight extends Component {
    render() {
        return (
            <MDBRow className="noSpace Copyright justify-content-center py-4 mt-5">
                <a //link to pdf asset
                    className="btn EnviarBtn px-4 py-3 GothamRoundedBold text-center justify-content-center"
                    href="http://blog.lexis.com.ec/blog/"
                    target="_blank"
                    rel="noopener noreferrer">
                    BLOG DE LEXIS
                </a>
            </MDBRow>
        )
    }
}
