import React, { Component } from 'react'

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";
import { MDBRow, MDBCol, MDBTooltip } from 'mdbreact';

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

registerLocale('es', es)



export default class RoToolbar extends Component {


    render() {
        return (
            <ProductConsumer>
                {context => {
                    return (
                        <MDBRow className="noSpace  h-100 my-auto text-center py-2">
                            <MDBCol className="noSpace" size="9">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="tooltipToHide2"
                                >
                                    <select
                                        className="toolbarInput roTypeToolbarInput text-center my-auto mx-auto font-weight-bolder"
                                        type="text"
                                        value={context.RoType}
                                        onChange={e => context.handleTypeROChange(e)}
                                    >
                                        <option className="myOption" onMouseOver={e => (e.currentTarget.style.backgroundColor = "#FF0000")} value="0">Todos</option>
                                        <option className="myOption" value="Registro Oficial">Registro Oficial</option>
                                        <option className="myOption" value="Registro Oficial Suplemento">Registro Oficial Suplemento</option>
                                        <option className="myOption" value="Registro Oficial Edición Constitucional">Registro Oficial Edición Constitucional</option>
                                        <option className="myOption" value="Registro Oficial Edición Especial">Registro Oficial Edición Especial</option>
                                        <option className="myOption" value="Registro Oficial Edición Jurídica">Registro Oficial Edición Jurídica</option>
                                        <option className="myOption" value="Diario de la Convención Nacional">Diario de la Convención Nacional</option>
                                    </select>{/*Page input*/}
                                    <span>"Elija un tipo de Registro"</span>
                                </MDBTooltip>

                            </MDBCol>
                            <MDBCol className="noSpace" size="3">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="tooltipToHide2"
                                >
                                    <input
                                        className="toolbarInput roNumberToolbarInput text-center my-auto mx-auto font-weight-bolder"

                                        type="number"

                                        value={context.RoNum}
                                        onChange={e => context.handleNumROChange(e, this.props.searchType)}
                                    ></input>{/*Page input*/}
                                    <span>"Elija un número de RO"</span>
                                </MDBTooltip>

                            </MDBCol>
                        </MDBRow>
                    )
                }}
            </ProductConsumer>
        )
    }
}
