import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

//Import material design components
import { MDBCol, MDBRow } from "mdbreact";

//Import icon reusable component
import GenericIcon from "./GenericIcon"

//import icon list
import { IconList } from "./IconList"

//Import pagination library
import Pagination from 'react-paginating';


export default class MyPagination extends Component {
    handleChange=(date)=> {
        console.log(date);
      }
    render() {
        return (
            <ProductConsumer>
                {context => {
                  
                    return (
                        <Pagination //Pagination defnition
                            className="noSpace h-100 my-auto justify-content-center mx-auto"
                            total={this.props.total} //total pages
                            limit={this.props.limit}
                            pageCount={this.props.pageCount}

                        >
                            {({
                                pages,
                                hasNextPage,
                                hasPreviousPage,
                                currentPage,
                                totalPages,
                                getPageItemProps
                            }) =>


                                (
                                   
                                            <MDBRow className="noSpace justify-content-center h-100 py-2">
                                                <MDBCol className="noSpace text-center my-auto" size="2">{/*First page container*/}
                                                    <GenericIcon
                                                        estilo="PaginationBtn my-auto"
                                                        {...getPageItemProps({
                                                            pageValue: 1,
                                                        })}
                                                        disabled={context.currentPage === 1 ? true : false}
                                                        icon={IconList[0].First} tooltip="Primera Página"
                                                        clic={() => context.handlePageChange(1, totalPages)} />

                                                </MDBCol>
                                                <MDBCol className="noSpace text-center my-auto" size="2">{/*Previous page container*/}
                                                    <GenericIcon
                                                        estilo="PaginationIcon my-auto"
                                                        {...getPageItemProps({
                                                            pageValue: parseInt(context.currentPage) - 1,

                                                        })}
                                                        clic={() => context.handlePageChange(parseInt(context.currentPage) - 1, totalPages)}
                                                        disabled={context.currentPage === 1 ? true : false}
                                                        icon={IconList[0].LeftArrow} tooltip="Anterior" />

                                                </MDBCol>
                                                <MDBCol className="noSpace my-auto text-center my-auto" size="4">{/*Page input container*/}
                                                    {pages.map(page => {
                                                        let activePage = null;
                                                        if (currentPage === page) {
                                                            activePage = { backgroundColor: "var(--mainZone)" };
                                                        }
                                                        //console.log(totalPages)
                                                        return (
                                                            <MDBRow className="noSpace">
                                                                <MDBCol size="6" className="noSpace text-right">
                                                                    <input
                                                                        className="PaginationInput text-center my-auto mx-auto font-weight-bolder"
                                                                        min={1}
                                                                        type="number"
                                                                        max={totalPages}
                                                                        value={context.currentPage}
                                                                        onChange={e => context.handlePageInputChange(e, totalPages)}
                                                                    ></input>{/*Page input*/}
                                                                </MDBCol>
                                                                <MDBCol size="6" className="noSpace my-auto">{/*total page indicator*/}
                                                                    <p className="noSpace text-white text-center my-auto mx-auto font-weight-bolder">{"  /  " + totalPages}</p>
                                                                </MDBCol>
                                                            </MDBRow>
                                                        );
                                                    })}

                                                </MDBCol>
                                                <MDBCol className="noSpace text-center my-auto" size="2">{/*Next page container*/}
                                                    <GenericIcon
                                                        estilo="PaginationIcon py-0 my-auto"
                                                        {...getPageItemProps({
                                                            pageValue: parseInt(context.currentPage) + 1
                                                        })}
                                                        clic={() => context.handlePageChange(parseInt(context.currentPage) + 1, totalPages)}
                                                        disabled={context.currentPage === this.props.total ? true : false}
                                                        icon={IconList[0].RightArrow} tooltip="Siguiente" />


                                                </MDBCol>
                                                <MDBCol className="noSpace text-center my-auto" size="2">{/*Last page container*/}
                                                    <GenericIcon
                                                        estilo="PaginationIcon my-auto"
                                                        {...getPageItemProps({
                                                            pageValue: totalPages,
                                                        })}
                                                        clic={() => context.handlePageChange(totalPages, totalPages)}
                                                        disabled={context.currentPage === this.props.total ? true : false}
                                                        icon={IconList[0].Last} tooltip="Última Página" />

                                                </MDBCol>
                                            </MDBRow>
                                      
                                )}
                        </Pagination>
                    )
                }}
            </ProductConsumer>

        )
    }
}
