import febrero2020 from "./2020-02-estadisticas.pdf"
import abril2020 from "./2020-04-estadisticas.pdf"
import mayo2020 from "./2020-05-estadisticas.pdf"
import junio2020 from "./2020-06-estadisticas.pdf"
import julio2020 from "./2020-07-estadisticas.pdf"

export const indice=[
  {
      "year":2020,
    "months": [
      { "id": 0, "mes":"Febrero", "file": "2020-02-estadisticas.pdf", link:febrero2020 },
      { "id": 1, "mes":"Abril", "file": "2020-04-estadisticas.pdf", link:abril2020 },
      { "id": 2, "mes":"Mayo", "file": "2020-05-estadisticas.pdf", link:mayo2020 },
      { "id": 3, "mes":"Junio", "file": "2020-06-estadisticas.pdf", link:junio2020 },
      { "id": 4, "mes":"Julio", "file": "2020-07-estadisticas.pdf",link: julio2020 }
    ]
  }
]
